<template>
    <div>
        <!-- 面包屑 -->
        <!-- <div class="breadcrumb">
            <el-breadcrumb>
                <el-breadcrumb-item :to="{ path: '/enterpriseHome/enterpriseContent' }">店铺首页</el-breadcrumb-item>
                <el-breadcrumb-item>公司信息</el-breadcrumb-item>
            </el-breadcrumb>
        </div> -->

        <!-- 主题内容 -->
        <div class="content">
            <div class="title">{{ storeInfo.storeName }}</div>
            <div class="introduction">公司介绍</div>
            <div class="infoImg">
                <div class="img">
                    <img :src="storeInfo.storeLog" style="object-fit: contain;"
                        alt="">
                </div>
                <div class="text">
                   {{ storeInfo.storeDesc || '--公司描述--' }}

                </div>
            </div>
            <div class="basicInfo">
                <div class="son">
                    <div class="l">公司名称： <span>{{ storeInfo.storeName || '--' }}</span></div>
                    <div class="r">员工人数： <span>{{storeInfo.employeesNum || '--'}} 人</span></div>
                </div>
                <div class="son">
                    <div class="l">公司面积： <span>{{storeInfo.companyArea|| '--'}} 平方米</span></div>
                    <!-- <div class="r">设备数量： <span>36台</span></div> -->
                </div>
                <div class="son">
                    <div class="l">成立时间： <span>{{storeInfo.companyFounded||'--'}} 年</span></div>
                </div>
                <div class="infoBg">
                    公司基本信息
                </div>
            </div>
            <div class="shouqImg">
                <div class="img" v-for="(i,index) in storeInfo.mallStoreQualifications" :key="index">
                    <img :src="i.qualificationsUrl"
                        alt="">
                </div>
            </div>
            <div class="introduction">店铺介绍</div>
            <div class="shopIsIntroduced">
                {{ storeInfo.storeDesc || '--店铺介绍--' }}
               </div>
            <div class="basicInfo2">
                <div class="infoBg">
                    店铺基本信息
                </div>
                <div class="top">
                    <div class="img">
                        <img :src="storeInfo.storeLog" style="object-fit: contain;" alt="">
                    </div>
                    <div class="r">
                        <div class="son">
                            <div class="text">商家名称：<span>{{ storeInfo.storeName || '--' }}</span></div>
                            <div class="text">商家等级：<span>{{storeInfo.storeGrade}}级</span></div>
                        </div>
                        <div class="son">
                            <div class="text">店铺类型：<span>{{ storeInfo.storeTypeTxt || '--'  }}</span></div>
                        </div>
                        <div class="son">
                            <div class="text">所在城市：<span>{{storeInfo.county ? storeInfo.province + storeInfo.city + storeInfo.county : '--'}}</span></div>
                            <div class="text">详细地址：<span>{{storeInfo.address ? storeInfo.address : '--'}}</span></div>
                        </div>
                        <div class="son">
                            <div class="text" style="width: 800px;">主营业务：{{storeInfo.storeTags ? storeInfo.storeTags : '--'}}<span> </span></div>
                        </div>
                    </div>

                </div>
                <div class="bot">
                    <div class="son">
                        <div class="name_content">
                            <div class="tilt">综合体验：</div>
                            <div class="rate"><el-rate v-model="storeInfo.mallStoreEvaluation.serviceValue" disabled-void-color="#C7C5C5" disabled
                                    :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                </el-rate></div>
                        </div>
                        <div class="name_content">
                            <div class="tilt">咨询评价：</div>
                            <div class="rate"><el-rate v-model="storeInfo.mallStoreEvaluation.consultValue" disabled-void-color="#C7C5C5" disabled
                                    :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                </el-rate></div>
                        </div>
                    </div>
                    <div class="son">
                        <div class="name_content">
                            <div class="tilt">服务评价：</div>
                            <div class="rate"><el-rate v-model="storeInfo.mallStoreEvaluation.consultValue" disabled-void-color="#C7C5C5" disabled
                                    :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                </el-rate></div>
                        </div>
                        <div class="name_content">
                            <div class="tilt">质量评价：</div>
                            <div class="rate"><el-rate v-model="storeInfo.mallStoreEvaluation.qualityValue" disabled-void-color="#C7C5C5" disabled
                                    :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                </el-rate></div>
                        </div>
                    </div>
                    <div class="son">
                        <div class="name_content">
                            <div class="tilt">物流评价：</div>
                            <div class="rate"><el-rate v-model="storeInfo.mallStoreEvaluation.logisticsValue" disabled-void-color="#C7C5C5" disabled
                                    :colors="['#FD4C4C', '#FD4C4C', '#FD4C4C']">
                                </el-rate></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStoreInfo } from "@/utils/api/homeApi/index"
export default {
    data() {
        return {
            value: 4,
            // d店铺信息
            storeInfo: {
                mallStoreEvaluation:{}
            },
            // 店铺id
            ids:''
        }
    },
    mounted() {
        this.ids = localStorage.getItem('storeId')
        this.getStoreInfoData()
    },
    methods: {
        async getStoreInfoData(){
            const res = await getStoreInfo(this.ids)
            if(res.statusCode == 8201){
                this.storeInfo = res.data
            }

        }
    }
}
</script>

<style lang="less" scoped>
.breadcrumb {
    width: 1200px;
    margin: 20px auto;
    padding: 0 20px;
    box-sizing: border-box;
}

.content {
    width: 1200px;
    height: auto;
    margin: 20px auto;
    padding: 45px 70px;
    box-sizing: border-box;
    background-color: #fff;

    >.title {
        width: 100%;
        font-size: 36px;
        color: #333;
        text-align: center;
    }

    >.introduction {
        color: #333;
        font-size: 24px;
        margin-top: 30px;
    }

    >.infoImg {
        width: 100%;
        height: 260px;
        display: flex;
        margin-top: 20px;

        >.img {
            width: 260px;
            height: 260px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        >.text {
            width: 750px;
            font-size: 14px;
            color: #333;
            margin-left: 30px;
            margin-top: 15px;
        }
    }

    >.basicInfo {
        width: 1050px;
        height: 190px;
        padding: 57px 30px 0 30px;
        box-sizing: border-box;
        background-color: #F5F5F5;
        margin-top: 30px;
        position: relative;

        >.infoBg {
            width: 150px;
            height: 36px;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../../assets/jbxx.png);
            background-size: 100% 100%;
            text-align: center;
            line-height: 36px;
            color: #fff;
        }

        >.son {
            width: 100%;
            display: flex;
            margin-bottom: 20px;

            >.l,
            .r {
                width: 400px;
                font-size: 16px;
                color: #666;

                >span {
                    color: #333;
                }
            }
        }
    }

    >.shouqImg {
        width: 100%;
        height: 220px;
        display: flex;
        margin-top: 30px;

        >.img {
            width: 312px;
            height: 220px;
            margin-right: 30px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    >.shopIsIntroduced {
        font-size: 14px;
        color: #333;
        margin-top: 20px;
    }

    >.basicInfo2 {
        width: 1050px;
        height: 458px;
        padding: 57px 30px 0 30px;
        box-sizing: border-box;
        background-color: #F5F5F5;
        margin-top: 30px;
        position: relative;

        >.infoBg {
            width: 150px;
            height: 36px;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url(../../assets/jbxx2.png);
            background-size: 100% 100%;
            text-align: center;
            line-height: 36px;
            color: #fff;
        }

        >.top {
            width: 100%;
            height: auto;
            display: flex;
            padding-bottom: 30px;
            box-sizing: border-box;
            border-bottom: 1px dashed rgba(0, 0, 0, 0.20);

            >.img {
                width: 128px;
                height: 128px;
                overflow: hidden;
                border-radius: 50%;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.r {
                width: 800px;
                height: auto;
                margin-left: 35px;

                >.son {
                    width: 100%;
                    display: flex;
                    margin-bottom: 20px;

                    >.text {
                        width: 400px;
                        font-size: 16px;
                        color: #666;

                        >span {
                            color: #333333;
                        }
                    }
                }
            }
        }

        >.bot {
            width: 600px;
            height: auto;
            margin: 35px auto;

            >.son {
                width: 100%;
                height: auto;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                >.name_content {
                    display: flex;
                    align-items: center;

                    >.rate {
                        padding-top: 4px;
                    }

                    >.tilt {
                        color: #666;
                    }
                }
            }
        }
    }
}

/deep/ .el-breadcrumb__inner.is-link:hover {
    color: #ff4242 !important;
}
</style>